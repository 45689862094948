import React from 'react'
import Countdown from 'react-countdown';
import { ClockIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { Typography } from '../../'

interface IMintCountDownTimer {
  singleLine?: boolean;
  mintDate: string;
  className?: string;
}

export const MintCountDownTimer:React.FC<IMintCountDownTimer> = ({
  singleLine = true,
  mintDate,
  className = 'bg-dark-400 px-4 py-2 text-primary-500 flex justify-center items-center'
}) => {
  
  dayjs.extend(utc)
  const countDownTimer = React.useMemo(() => {
    return dayjs(mintDate).utc()
  }, [mintDate])

  const MintStarted = () => {
    if (singleLine) {
      return (
        <Typography
          variant="subtitle1"
          className="text-primary-500 text-lg"
        >
          Minting Since: {countDownTimer.local().toString()}
        </Typography>
      )
    }
    return (
      <div className="mt-2 text-dark-500 text-sm bg-primary-200 rounded-full px-4 py-1 uppercase animate-pulse">Minting Now</div>
    )
  }

  type TRenderer = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }

  const renderer = ({
    days, hours, minutes, seconds, completed
  }: TRenderer) => {
    if (completed) {
    // Render a completed state
      return <MintStarted />;
    }

    if (singleLine) {
      // Render a countdown
      return (
        <Typography
          variant="subtitle1"
          className="text-primary-500 text-lg"
        >Minting Starts in: {days} Days, {hours} Hours, {minutes} Minutes, {seconds} Seconds
        </Typography>
      );
    }

    return (
      <div className="grid grid-cols-4">
        <div className="p-2 text-center uppercase">
          {/* <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 !text-xs"
          >Days/
          </Typography> */}
          <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 font-light text-sm"
          >{days}::
          </Typography>
        </div>
        <div className=" p-2 text-center uppercase ">
          {/* <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 !text-xs"
          >Hrs/
          </Typography> */}
          <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 font-light text-sm"
          >{hours}::
          </Typography>
        </div>
        <div className=" p-2 text-center uppercase ">
          {/* <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 !text-xs"
          >Min/
          </Typography> */}
          <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 font-light text-sm"
          >{minutes}::
          </Typography>
        </div>
        <div className=" p-2 text-center uppercase ">
          {/* <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 !text-xs"
          >Sec
          </Typography> */}
         <Typography
            variant="body1"
            as="div"
            className="!text-dark-500 font-light text-sm"
          >{seconds}
          </Typography>
        </div>
      </div>

    )
  };

  return (
    <div className={className}>
      {singleLine && (
      <ClockIcon className="h-4 w-4 flex-shrink-0 mx-2" />
      )}
      <Countdown
        // @ts-ignore
        now={() => dayjs().utc()}
        date={countDownTimer.local().toString()}
        renderer={renderer}
      />
    </div>
  )
}
